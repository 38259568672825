.svg-frame {
  position: relative;
  width: 300px;
  height: 300px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}

.svg-frame svg {
  position: absolute;
  -webkit-transition: .5s;
  transition: .5s;
  z-index: calc(1 - (0.2 * var(--j)));
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  width: 344px;
  height: 344px;
  fill: none;
}

.svg-frame:hover svg {
  -webkit-transform: rotate(-80deg) skew(30deg) translateX(calc(45px * var(--i))) translateY(calc(-35px * var(--i)));
      -ms-transform: rotate(-80deg) skew(30deg) translateX(calc(45px * var(--i))) translateY(calc(-35px * var(--i)));
          transform: rotate(-80deg) skew(30deg) translateX(calc(45px * var(--i))) translateY(calc(-35px * var(--i)));
}

.svg-frame svg #center {
  -webkit-transition: .5s;
  transition: .5s;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}

.svg-frame:hover svg #center {
  -webkit-transform: rotate(-30deg) translateX(45px) translateY(-3px);
      -ms-transform: rotate(-30deg) translateX(45px) translateY(-3px);
          transform: rotate(-30deg) translateX(45px) translateY(-3px);
}

#out2 {
  -webkit-animation: rotate16 7s ease-in-out infinite alternate;
          animation: rotate16 7s ease-in-out infinite alternate;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}

#out3 {
  -webkit-animation: rotate16 3s ease-in-out infinite alternate;
          animation: rotate16 3s ease-in-out infinite alternate;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  stroke: #a96d5a;
}

#inner3,
#inner1 {
  -webkit-animation: rotate16 4s ease-in-out infinite alternate;
          animation: rotate16 4s ease-in-out infinite alternate;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}

#center1 {
  fill: #a96d5a;
  -webkit-animation: rotate16 2s ease-in-out infinite alternate;
          animation: rotate16 2s ease-in-out infinite alternate;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}

@-webkit-keyframes rotate16 {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate16 {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}